import { Button as ReactstrapButton, ButtonProps } from 'reactstrap';

export const Button: React.FC<ButtonProps> = (props) => <ReactstrapButton
    onClick={props.onClick}
    style={{
        backgroundColor: 'white',
        border: 'solid 1px #364958',
        borderRadius: '20px',
        color: '#364958',
        fontSize: '20px',
        fontWeight: 'var(--font-weight-regular)',
        textTransform: 'uppercase',
    }}
>
    {props.children}
</ReactstrapButton>;