import HCaptcha from "@hcaptcha/react-hcaptcha";
import { ErrorMessage, Field, Formik } from "formik";
import { useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import * as Yup from 'yup';
import { hCaptchaSiteKey } from "../constant/hCaptcha";
import { powerAutomateUrl } from "../constant/powerAutomateUrl";
import { Button } from "./common/Button";
import { P } from "./common/P";
import './Contact.css';

const debug: boolean = false;

type SubjectType = 'Investors' | 'Partners';

interface ContactValues {
  name: string;
  email: string;
  subject: SubjectType,
  message: string;
  "h-captcha-response": string;
}

const initialValues: ContactValues = {
  name: '',
  email: '',
  subject: 'Partners',
  message: '',
  "h-captcha-response": '',
}

const schema = Yup.object({
  name: Yup.string().required("You must enter a name"),
  email: Yup.string().required("You must enter an email").email("You must enter a valid email"),
  subject: Yup.string().oneOf(['Partners', 'Investors']),
  message: Yup.string().required("You must enter a message"),
  "h-captcha-response": Yup.string().required("You must pass the CAPTCHA"),
});

const Required: React.FC = () => <span className={'required'}>*</span>;

export const Contact: React.FC = () => {
  const [thanks, setThanks] = useState(false);

return thanks ? <P>Thank you</P> : <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        fetch(powerAutomateUrl, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'x-resonance': 'resonance',
          },
          body: JSON.stringify(values)
        }).then(() => {
          actions.setSubmitting(false);
          setThanks(true);
         }).catch((reason) => 
          console.log(reason)
        );
      }}
      validateOnMount={true}
      validationSchema={schema}
    >
      {({
        errors,
        isSubmitting,
        isValid,
        setFieldValue,
        touched,
        values,
        handleSubmit
      }) =>
        <Form id="contact" onSubmit={handleSubmit}>
          { debug ? <>
            <h3>values</h3>
            <pre>{JSON.stringify(values, null, 2)}</pre>
            <h3>errors</h3>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            <h3>touched</h3>
            <pre>{JSON.stringify(touched, null, 2)}</pre>
            <h2>isSubmitting</h2>
            <pre>{isSubmitting ? 'true' : 'false'}</pre>
            <h2>isValid</h2>
            <pre>{isValid ? 'true' : 'false'}</pre>
          </> : null}
          
          <FormGroup className={'mb-4'}>
            <Label for="name">Name: <Required/></Label>
            <Input
              tag={Field}
              type="text"
              id="name"
              name="name"
              placeholder="Your full name"
              value={values.name}
            />
            <ErrorMessage name={'name'}/>
          </FormGroup>

          <FormGroup className={'mb-4'}>
            <Label for="email">Email: <Required/></Label>
            <Input
              tag={Field}
              type="email"
              id="email"
              name="email"
              placeholder="Your email address"
              value={values.email}
            />
            <ErrorMessage name={'email'}/>
          </FormGroup>

          <FormGroup className={'mb-4'}>
          <Label className={'d-block'}>Subject: <Required/></Label>
          <FormGroup tag={'fieldset'} inline={true} check>
                <Field type={'radio'} id={'partners'} name={'subject'} value={'Partners'}/>
                <Label for={'partners'} check>Partners</Label>
          </FormGroup>
          <FormGroup tag={'fieldset'} inline={true} check>
                <Field type={'radio'} id={'investors'} name={'subject'} value={'Investors'} />
                <Label for={'investors'} check>Investors</Label>
          </FormGroup>
          </FormGroup>

          <FormGroup className={'mb-4'}>
            <Label for={'message'}>Message: <Required/></Label>
            <Input
              tag={Field}
              type="text"
              id="message"
              name="message"
              placeholder="Please include a detailed message"
              value={values.message}
            />
            <ErrorMessage name={'message'}/>
          </FormGroup>

          {/* Graphics asked about the light border around the dark theme hCaptcha:
              it's inside the iframe element with no way to suppress in the configuration
              https://www.npmjs.com/package/@hcaptcha/react-hcaptcha
            */}
            <FormGroup  className={'mb-4'}>
            <HCaptcha
              onVerify={(token: string | null) => setFieldValue("h-captcha-response", token)}
              reCaptchaCompat={false}
              sitekey={hCaptchaSiteKey}
              theme={'dark'}
            />
            <ErrorMessage name={'h-captcha-response'}/>
            </FormGroup>


          <Button type={"submit"} disabled={isSubmitting || !isValid}>
            Submit
          </Button>
        </Form>}
    </Formik>;
}
