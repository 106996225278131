import { CSSProperties } from "react";

export const Logo: React.FC<{
    alt?: string;
    className?: string;
    style?: CSSProperties;
    theme?: 'light' | 'dark';
}> = ({
    alt,
    className = '',
    style,
    theme = 'dark',
}) => <img
    className={`img-fluid w-100 ${className || ''}`}
    src={`https://cdn2.caymanchem.com/cdn/cms/caymanchem/cmsImages/resonance/logos/resonance-logo${theme === 'light' ? '-white-2' : ''}.svg`}
    alt={alt || 'Resonance Pharma'}
    style={style}
    width={1000} height={1000}
    
/>;