import { Card, CardBody, CardTitle, Col, CardImg } from "reactstrap";
import './Biography.css';

export const Biography: React.FC<{
    src: string;
    name: string;
    url?: string;
    linkedIn?: string;
    role?: JSX.Element;
    enabled?: boolean;
}> = ({
    src,
    name,
    url,
    linkedIn,
    role,
    enabled = true
}) => enabled ? <Col xs={12} md={4} className={'pt-3 pt-md-0'}><Card className={'biography border-0'} style={{backgroundColor: 'var(--color-2)'}}>
    <CardImg 
        className={'rounded-3'} 
        src={`https://cdn2.caymanchem.com/cdn/cms/caymanchem/cmsImages/resonance/people/${src}.jpg`} 
        alt={name}/>
    <CardBody className={'background-2 px-0'}>
        <CardTitle className={'name'}>
            {url ? <a className={'d-block text-white'} href={url}>{name}</a> : name}
            <div>{role}</div>
            {linkedIn && <a className={'d-block text-uppercase text-white'} href={`https://www.linkedin.com/in/${linkedIn}`} target={"_blank"} rel={'noreferrer'}>LinkedIn</a>}
        </CardTitle>
    </CardBody>
</Card></Col> : null;