import { Col, NavLink, Row } from "reactstrap";
import { Logo } from "../component/Logo";
import { Year } from "../util/Year";
import './Footer.css';

export const Footer: React.FC = () => <Row id={'footer'} className={'background-1 pb-4'}>
    <Col md={6} className={'d-inline-flex justify-content-end align-bottom'}>
        <NavLink className={'text-light'} href={'/privacy'}>Privacy Policy</NavLink>
    </Col>
    <Col md={3} className={'address text-end offset-lg-2'}>
        <Logo theme={'light'}/>

        <div className={'pt-3'}>
            <div>Resonance Pharma, Inc.</div>
            <div>1180 East Ellsworth Road</div>
            <div>Ann Arbor, MI · 48108</div>
            <div>© <Year /></div>
        </div>
    </Col>
</Row>;