import { useState } from "react";
import { Row, Col, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, NavbarText } from "reactstrap";
import { Button } from "../component/common/Button";
import { Logo } from "../component/Logo";
import './Header.css';

const hamburgerMenu: boolean = true;

const ContactButton: React.FC = () => window.location.pathname === '/' ?
    <NavbarText><Button onClick={() => window.location.hash = 'contact'}>Contact</Button></NavbarText> :
    null
    ;

export const Header: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    return <Row className={'background-4 pt-4'}><Col md={10} className={'offset-md-1'}><Navbar container={false}>
        <NavbarBrand className={'me-auto'} href={'/'}><Logo/></NavbarBrand>
        <ContactButton />
        {hamburgerMenu ? <><NavbarToggler className={'text-light ms-3'} onClick={() => setIsOpen(!isOpen)}>☰</NavbarToggler>
        <Collapse className={'text-end'} isOpen={isOpen} navbar>
            <Nav navbar>
                <NavItem><NavLink href={'/'}>Home</NavLink></NavItem>
                <NavItem><NavLink href={'/privacy'}>Privacy Policy</NavLink></NavItem>
            </Nav>
        </Collapse></> : null}
    </Navbar></Col></Row>;
}