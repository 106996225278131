import { Row, Col } from "reactstrap";

export const Divider: React.FC<{ start: number, end?: number }> = ({ start, end }) => <Row>
    <Col className={'p-0'}>
        <svg
            className={'divider'}
            height={'20ex'}
            width={'100%'}
            preserveAspectRatio={'none'}
            style={{
                backgroundColor: `var(--color-${start})`,
            }}
            viewBox={'0 0 100 100'}>
            <polygon points={'0,100 100,100 100,0 0,100'} fill={`var(--color-${end || start - 1})`} />
        </svg>
    </Col>
</Row>;