import { Row, Col } from "reactstrap";
import { LI } from "../component/common/LI";
import { P } from "../component/common/P";
import { Divider } from "../component/Divider";
import { Headline } from "../component/typography/Headline";
import { ImpactHeadline } from "../component/typography/ImpactHeadline";
import { SubImpactHeadline } from "../component/typography/SubImpactHeadline";
import { Page } from "../layout/Page";

const Email: React.FC = () => <a href="mailto:info@resonancepharma.com">info@resonancepharma.com</a>;

export const Privacy: React.FC = () => <Page><Row className={'background-4'}><Col lg={10} className={'offset-lg-1'}>
    <ImpactHeadline className={'text-dark'}>Privacy Policy</ImpactHeadline>

    <em className={'body-copy text-dark'}>2022-06-08</em>

    <SubImpactHeadline className={'text-dark'}>Resonance Pharma</SubImpactHeadline>

    <P className={'text-dark'}>This Privacy Statement covers the site www.resonancepharma.com, operated by Resonance Pharma, and data collected through this property. We will notify you of any material changes to this policy <em>via</em> the most recent email we have on file or by prominently displaying it on our website. We encourage you to check back periodically and review this policy so you will always know what information we collect, how we use it, and with whom we share it.</P>

    <Headline className={'text-dark'}>What data</Headline>

    <P className={'text-dark'}>Resonance Pharma collects data from our users at several points including but not limited to web forms, email correspondence, and general inquiries. Data collected is always provided by you and may include any combination of the following:</P>

    <ul>
        <LI className={'text-dark'}>Full name</LI>
        <LI className={'text-dark'}>Position</LI>
        <LI className={'text-dark'}>Affiliated institution/organization</LI>
        <LI className={'text-dark'}>Web address</LI>
        <LI className={'text-dark'}>Phone number</LI>
        <LI className={'text-dark'}>Email address</LI>
        <LI className={'text-dark'}>Shipping, and/or mail addresses</LI>
        <LI className={'text-dark'}>Contact preferences</LI>
    </ul>

    <P className={'text-dark'}>Data you provide <em>via</em> direct communication is stored for the purpose of facilitating communications and conducting transactions and will only be used for that purpose. Resonance Pharma’s experts may use the data you provide to connect you with other products and services we offer to better serve you.</P>

    <Headline className={'text-dark'}>Consent</Headline>

    <P className={'text-dark'}>We seek active consent through an opt-in process to use your data for communication purposes. You have the right to withdraw this consent at any time by emailing <Email/>. We may continue to store that data and communicate with you to carry out contractual obligations with you. Resonance Pharma will not retain, use, or disclose your data for any purpose other than the specific purpose of performing the services specified at the time of collection.</P>

    <Headline className={'text-dark'}>How to Change Your Data</Headline>

    <P className={'text-dark'}>We rely on the data you give us, so it is important that all of the details that you give us are accurate and up-to-date. If your data changes, please notify us at <Email/>.</P>

    <Headline className={'text-dark'}>DO NOT TRACK REQUESTS</Headline>

    <P className={'text-dark'}>
        Because we do not track our users over time, we do not respond to browser do-not-track signals.
    </P>

    <Headline className={'text-dark'}>Third Parties</Headline>

    <P className={'text-dark'}>We will not disclose your personal information to third parties, except:</P>

    <ol type={'a'}>
        <LI className={'text-dark'}>With your consent</LI>
        <LI className={'text-dark'}>To our subsidiaries, affiliates, and service providers to fulfill product orders, deliver services or otherwise to fulfill a contractual obligation to you</LI>
        <LI className={'text-dark'}>To service providers who act on our behalf and under our instructions to perform certain functions (<em>e.g.,</em> IT companies, legal, financial, and other advisors)</LI>
        <LI className={'text-dark'}>As required by applicable law, such as to cooperate with law enforcement investigations or upon receipt or a court order. Service providers acting on our behalf are obligated to comply with this Policy and will only receive access to data as necessary to perform their functions.</LI>
    </ol>

    <Headline className={'text-dark'}>Google Analytics</Headline>
    <P className={'text-dark'}>Data specific to the use of Resonance Pharma, including which pages are visited, what technology is used (operating system, browser, etc.), and user demographic data including age, gender, and interests is collected through Google Analytics. Google Analytics uses cookies to identify and track website visits and you can find their privacy policy <a href={'https://policies.google.com/privacy?hl=en-US'}>here</a>. Any data collected regarding use of the website is used solely to improve our site and to better serve you. If you would like to opt out of Google Analytics tracking, you can do so with the <a href={'https://tools.google.com/dlpage/gaoptout/'}>Google Analytics Opt-out Browser Add-on</a>. </P>

    <Headline className={'text-dark'}>Protection and Security </Headline>
    <P className={'text-dark'}>Resonance Pharma takes reasonable precautions to protect your data. When you must supply sensitive data, such as credit card or social security numbers, that data is encrypted and protected. All of your data is stored in facilities with restricted access. Resonance Pharma’s internal network is protected by reasonable security systems and practices.</P>
</Col></Row>
<Divider start={4} end={1}/>
</Page>;