import { Row, Col } from 'reactstrap';
import { Biography } from '../component/Biography';
import { Contact } from '../component/Contact';
import { Headline } from '../component/typography/Headline';
import { P } from '../component/common/P';
import { Page } from '../layout/Page';
import { Divider } from '../component/Divider';
import { SPla2 } from '../component/SPla2';

export const Home: React.FC = () => <Page>
    <Divider start={4}/>

    <Row className={'background-3 pt-5'}>
        <Col lg={5} className={'order-2 order-md-1 offset-lg-1'}>
            <img
                alt={'Hero'}
                className={'img-fluid rounded-3'}
                src={'https://cdn2.caymanchem.com/cdn/cms/caymanchem/cmsImages/resonance/lab-photo.jpg'}
            />
        </Col>
        <Col lg={4} className={'order-1 order-md-2 offset-lg-1 pt-3 pt-lg-0'}>
            <Headline>About</Headline>

            <P>
                Resonance Pharma is a strategic partnership between lipid R&amp;D powerhouse, Cayman Chemical Company, and world-class experts in personalized medicine.
                This alliance leverages six decades of combined experience in the areas of lipid chemistry, biochemistry, and genetics with an intense focus
                on the role of lipids in disease biology to discover, develop, and deliver innovative diagnostics and medicines. This approach will ultimately
                advance precision therapeutics to address some of the world's most pressing and devastating diseases.
            </P>
        </Col>
    </Row>
    <Row className={'background-3 d-none'}>
        <Col lg={10} className={'offset-lg-1 pt-5'}>
            <Headline>Overview</Headline>

            <P>
                Secreted phospholipase A<sub>2</sub> (<SPla2/>) is a family of enzymes that initiates the production of inflammatory lipid metabolites. Many of these lipid metabolites are implicated in the pathogenesis of severe and life-threatening conditions, like cancer, sepsis, and COVID-19. Blocking <SPla2/> stops this inflammatory cascade at its origin, making <SPla2/> an attractive target with untapped therapeutic potential. 
            </P>
            
            <P>
                A one-size-fits-all approach to <SPla2/> inhibition – like others have tried previously – will not work.
            </P>

            <P>
                At Resonance, we understand that there are many PLA<sub>2</sub> isoforms, and individual isoforms have distinct roles in health and disease. Our precision medicine toolset identifies and targets individual <SPla2/> isoforms with diagnostic-drug pairs to get the right medicine to the right patient at the right time.  
            </P>
        </Col>
    </Row>
    <Row className={'background-3 d-none'}>
        <Col lg={10} className={'offset-lg-1 pt-5'}>
            <Headline>Approach</Headline>

            <P>
                Resonance Pharma is building a precision medicine toolset for <SPla2/> through the rapid discovery and development of isoform-selective <SPla2/> diagnostic-drug pairs by leveraging our unrivaled expertise and in-house drug discovery and commercial assay development programs.  
            </P>
        </Col>
    </Row>
    <Row className={'background-3'}>
        <Col lg={10} className={'offset-lg-1 pt-5'}>
            <Headline>Opportunity</Headline>

            <P>
                Resonance Pharma is seeking clinical, regulatory, and investment partners to help advance its next generation of diagnostic and therapeutic tools
                from development to commercialization. These tools are focused on a core set of therapeutic areas, including sepsis, cancer, and infection,
                and represent fundamentally new approaches to address patients&#x2019; most serious health issues. Resonance Pharma will leverage intellectual
                property centered on its assay technology, stratified clinical approaches, and novel compositions of matter to support these goals.
            </P>
        </Col>
    </Row>

    <Divider start={3}/>

    <Row className={'background-2 pt-5 pb-3'}>
        <Col lg={10} className={'offset-lg-1'}>
            <Headline>Meet the Team</Headline>
            <Row className={'justify-content-between'}>
                <Biography
                    src={'maxey'}
                    name={'Kirk Maxey, MD'}
                    role={<>Co-Founder &amp; Chairman of the Board</>}
                    linkedIn={'kirk-maxey-13333b6'}
                />
                <Biography
                    src={'chilton'}
                    name={'Floyd “Ski” Chilton, PhD'}
                    role={<>Co-Founder &amp; Chief Scientific Officer</>}
                    linkedIn={'ski-floyd-h-chilton-933b5015'}
                />
                <Biography
                    src={'kinch'}
                    name='Michael S. Kinch, PhD'
                    role={<>Chief Executive Officer</>}
                    linkedIn={'michaelkinch'}
                />
                <Biography
                    src={'toker'}
                    name='Emre Toker'
                    role={<>Chief Business Officer &amp; Board of Directors </>}
                    linkedIn={'emre-toker'}
                />
                <Biography
                    src={'barrett'}
                    name={'Stephen D. Barrett'}
                    role={<>Chief Operating Officer</>}
                    linkedIn={'stephen-barrett-412a613'}
                />
                <Biography
                    src={'stacey'}
                    name={'Shannon Stacey'}
                    role={<>Vice President of Regulatory Affairs</>}
                    linkedIn={'shannon-stacey-23338911'}
                />
            </Row>
        </Col>
    </Row>

    <Row className={'background-2 pt-5'} style={{borderTop: 'solid 3px rgb(51,51,51)'}}>
        <Col lg={5} className={'offset-lg-1'}>
            <Headline>Contact Us</Headline>
            <P>
                Contact us to discuss investment and partnership opportunities or learn more about Resonance Pharma.
            </P>
        </Col>
        <Col lg={5}>
            <Contact />
        </Col>
    </Row>

    <Divider start={2}/>
</Page>;