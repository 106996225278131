import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container } from 'reactstrap';
import './App.css';
import { Footer } from './layout/Footer';
import { Header } from './layout/Header';
import { Home } from './page/Home';
import { NotFound } from './page/NotFound';
import { Privacy } from './page/Privacy';

function App() {
  return (
      <Container className={'App h-100'} fluid={true} style={{backgroundImage: window.location.pathname === '/' ? 'url(https://cdn2.caymanchem.com/cdn/cms/caymanchem/cmsImages/resonance/primary-banner.jpg)' : 'none'}}>
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path={'/privacy'} element={<Privacy />} />
            <Route path={'/'} element={<Home />} />
            <Route path={'*'} element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </Container>
  );
}

export default App;
